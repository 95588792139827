import React, { useEffect } from "react";
import {
  Drawer,
  Avatar,
  List,
  ListItemButton,
  Container,
  Button,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { M3BodyLarge, M3LabelMedium } from "./reusable/TextStyles";
import Loading from "./reusable/Loading";
import styled from "@emotion/styled";
import LogoutIcon from "@mui/icons-material/Logout";
import CheckListIcon from "@mui/icons-material/Checklist";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LoginIcon from "@mui/icons-material/Login";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import { ReactComponent as Logo } from "../assets/Logo.svg";
import { useLocation } from "react-router-dom";
import theme from "../utils/theme";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import quickcareLogo from "../assets/splash.png";
import { PaddingBox } from "./reusable/Scaffolds";
const drawerWidth = '290px';
const drawerWidthClosed = '70px';
const openedMixin = (theme) => ({
  width: drawerWidth,
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  overflowX: "hidden",
  width: drawerWidthClosed,
});

const Layout = ({
  children,
  isLoggedIn,
  isAdmin,
  onLogout,
  loading,
  currentUser,
  currentRole,
}) => {
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const location = useLocation();
  const getLinks = () => {
    if (!isLoggedIn) {
      return [
        {
          route: "/login",
          name: "Login",
          icon: <LoginIcon />,
        },
      ];
    }
    if (isAdmin) {
      return [
        {
          route: "/educators",
          name: "Educators",
          icon: <SchoolOutlinedIcon />,
        },
        {
          route: "/applications",
          name: "Applications",
          icon: <CheckListIcon />,
        },
        {
          route: "/shifts",
          name: "Shifts",
          icon: <ListAltIcon />,
        },
        {
          route: "/logins-manage",
          name: "Manage logins",
          icon: <SupervisorAccountOutlinedIcon />,
        },
        {
          route: "/organisation",
          name: "Organisations",
          icon: <LocationCityOutlinedIcon />,
        },
      ];
    }

    if (["owner", "manager", "support"].includes(currentRole)) {
      return [
        {
          route: "/shifts",
          name: "Shifts",
          icon: <ListAltIcon />,
        },
        {
          route: "/educators",
          name: "Educator management",
          icon: <SchoolOutlinedIcon />,
        },
        {
          route: "/organisation",
          name: "Organisation",
          icon: <LocationCityOutlinedIcon />,
        },
        {
          route: "/logins-manage",
          name: "Manage logins",
          icon: <SupervisorAccountOutlinedIcon />,
        },
      ];
    }

    return [];
  };
  const isHidden =
    location.pathname.includes("/signup") ||
    location.pathname.includes("/reset/") ||
    location.pathname.includes("/stripe");
  useEffect(() => {
    if (!isLoggedIn) {
      setOpen(true)
    }
  }
  , [isLoggedIn])
  return (
    <div style={{ display: "flex" }}>
      {isHidden ? null : (
        <div>
          <CustomDrawer
            PaperProps={{ style: { width: open ? drawerWidth : drawerWidthClosed, } }}
            variant="permanent"
            open={open}
            style={{
              width: open ? drawerWidth : drawerWidthClosed,
              display:
                !isAdmin &&
                (currentRole === "educator" || currentRole === null) &&
                isLoggedIn
                  ? "none"
                  : "",
            }}
          >
            {open ? (
              <LogoContainer>
                <Link to="/">
                  <Logo />
                </Link>
              </LogoContainer>
            ) : (
              <Link to="/">
                <img
                  src={quickcareLogo}
                  alt="Logo"
                  style={{
                    width: "50px",
                    height: "50px",
                    margin: "8px",
                    borderRadius: "50%",
                  }}
                ></img>
              </Link>
            )}

            <List>
              {getLinks().map((link) => (
                <Tooltip
                  title={link.name}
                  placement="right"
                  disableHoverListener={open}
                >
                  <SelectedItem
                    key={link.route}
                    component={Link}
                    route={link.route}
                    sx={{
                      backgroundColor:
                        location.pathname === link.route
                          ? theme.palette.secondary.main
                          : null,
                    }}
                    selected={location.pathname === link.route}
                    to={link.route}
                  >
                    <Row>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {link.icon}
                      </Box>
                      <M3BodyLarge
                        style={{ paddingLeft: open ? "16px" : "0px" }}
                        sx={[
                          open
                            ? {
                                display: "block",
                              }
                            : {
                                display: "none",
                              },
                        ]}
                      >
                        {link.name}
                      </M3BodyLarge>
                    </Row>
                  </SelectedItem>
                </Tooltip>
              ))}
            </List>
            {isLoggedIn && (
              <ProfileSection open={open}>
                {!open ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Tooltip
                      title={`${currentUser?.first_name} ${currentUser?.last_name}`}
                      placement="right"
                      disableHoverListener={open}
                    >
                      <CustomAvatar
                        src={currentUser?.avatar_url}
                        sizes="small"
                        {...stringAvatar(
                          `${currentUser?.first_name} ${currentUser?.last_name}`
                        )}
                      />
                    </Tooltip>
                    <PaddingBox />
                    <Tooltip
                      title={`Logout`}
                      placement="right"
                      disableHoverListener={open}
                    >
                      <IconButton
                        onClick={handleDrawerOpen}
                        sx={{
                          borderRadius: "5px",
                          color: theme.palette.primary.main,
                          backgroundColor: theme.palette.primaryContainer.main,
                        }}
                      >
                        <LogoutIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  <Row>
                    <CustomAvatar
                      style={{ marginRight: 16 }}
                      src={currentUser?.avatar_url}
                      {...stringAvatar(
                        `${currentUser?.first_name} ${currentUser?.last_name}`
                      )}
                    />
                    <List>
                      <M3LabelMedium>Logged in as</M3LabelMedium>
                      <M3BodyLarge style={{width: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        {currentUser?.first_name} {currentUser?.last_name}
                      </M3BodyLarge>
                    </List>
                    <Button
                      style={{
                        marginLeft: 16,
                        padding: "8px 16px",
                        textTransform: "none",
                        color: theme.palette.primaryContainer.onPrimary,
                        backgroundColor: theme.palette.primaryContainer.main,
                      }}
                      onClick={onLogout}
                      startIcon={<LogoutIcon />}
                    >
                      Logout
                    </Button>
                  </Row>
                )}
              </ProfileSection>
            )}
          </CustomDrawer>
          {(isLoggedIn && !loading) && (
            <IconButton
              onClick={handleDrawerOpen}
              sx={{
                zIndex: 2000,
                top: "10%",
                position: "fixed",
                width: "32px",
                height: "32px",
                marginLeft: open ? "274px" : "52px",
                color: "white",
                backgroundColor: "black",
              }}
            >
              {open ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          )}
        </div>
      )}
      <Container maxWidth="lg" style={{ flexGrow: 1, padding: "20px" }}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Box
              sx={{
                height: "48px",
              }}
            />
            {children}
          </>
        )}
      </Container>
    </div>
  );
};

const LogoContainer = styled.div`
  padding: 32px;
`;
export const CustomAvatar = styled(Avatar)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.onPrimary};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      "& .MuiPaper-root": {
      ...openedMixin(theme),
    },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));
const ProfileSection = styled.div`
  margin-top: auto;
  background-color: ${(props) => props.theme.palette.surfaceContainerLow.main};
  padding: ${(props) => props.open && "16px"};
  padding-top: ${(props) => !props.open && "16px"};
  padding-bottom: ${(props) => !props.open && "16px"};
`;
const SelectedItem = styled(ListItemButton)`
  color: ${(props) => props.theme.colors.text};
  margin: 5px;
  margin-top: ${(props) =>
    props.route === "Organisations-Private" ? "60vh" : "5px"};
`;
export default Layout;

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
