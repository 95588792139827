
import React, { useEffect } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {post} from '../../utils/api';
import { CircularProgress, Container } from '@mui/material';

const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY

function StripeWrapperPage() {
  const [somethingWentWrong, setSomethingWentWrong] = React.useState('');
  useEffect(() => {
    initStripe()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function initStripe() {
    try {
      const res = await post("/create-verification-session");
      window.flutter_inappwebview.callHandler("messageHandler", "CREATED");
      const { client_secret } = res;
      const stripe = await loadStripe(stripePublishableKey);
      const { error } = await stripe.verifyIdentity(client_secret);
      if (error) {
        console.log("[error]", error);
        window.flutter_inappwebview.callHandler("messageHandler", "ERROR");
      } else {
        window.flutter_inappwebview.callHandler("messageHandler", "DONE");
        console.log("Verification submitted!");
      }
    } catch (error) {
      setSomethingWentWrong(error.message);
      rerfreshPage();
    }
  }
  function rerfreshPage() {
    window.location.reload();
  }

  return (
    <Container>
      {somethingWentWrong && <div sx={{
        color: 'red',
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        margin: '1rem 0'
      }} >{somethingWentWrong}</div>}
      <CircularProgress 
        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} 
      />
    </Container>
  );
}

export default StripeWrapperPage;