import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import {
  AVAILABLE_TO_WORK,
  CANCELLED,
  COMPLETED,
  EXPIRED,
  FILLED,
  FINISHED,
  IN_PROGRESS,
  OPEN,
  UNAVAILABLE_TO_WORK
} from '../../utils/constants'
import theme from '../../utils/theme'
import { CustomAvatar } from '../Layout'
export const Tags = ({ type }) => {
  return (
    <CustomChip
      icon={getIcon(type)}
      label={getLabel(type)}
      style={{
        backgroundColor: getBackgroundColor(type),
        borderColor: type === FILLED ? getColor(type) : null,
        borderWidth: type === FILLED ? '1px' : null,
        borderStyle: type === FILLED ? 'solid' : null,
        color: getColor(type),
        textTransform: 'uppercase',
        borderRadius: '4px'
      }}
      variant="filled"
    />
  )
}
const CustomChip = styled(Chip)`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
`

function getBackgroundColor(type) {
  switch (type) {
    case OPEN:
      return theme.palette.tags.open.backgroundColor
    case FILLED:
      return theme.palette.tags.filled.backgroundColor
    case IN_PROGRESS:
      return theme.palette.tags.inProgress.backgroundColor
    case COMPLETED:
      return theme.palette.tags.completed.backgroundColor
    case FINISHED:
      return theme.palette.tags.finished.backgroundColor
    case EXPIRED:
      return theme.palette.tags.expired.backgroundColor
    case CANCELLED:
      return theme.palette.tags.cancelled.backgroundColor
    case UNAVAILABLE_TO_WORK:
      return theme.palette.tags.unavailableToWork.backgroundColor
    case AVAILABLE_TO_WORK:
      return theme.palette.tags.availableToWork.backgroundColor
    case 7:
      return theme.palette.tags.quickcare.backgroundColor
    default:
      return theme.palette.tags.open.backgroundColor
  }
}
function getColor(type) {
  switch (type) {
    case FILLED:
      return theme.palette.tags.filled.color 
    case IN_PROGRESS:
      return theme.palette.tags.inProgress.color
    case COMPLETED:
      return theme.palette.tags.completed.color
    case EXPIRED:
      return theme.palette.tags.expired.color
    case CANCELLED:
      return  theme.palette.tags.cancelled.color
    case UNAVAILABLE_TO_WORK:
      return  theme.palette.tags.unavailableToWork.color
    case AVAILABLE_TO_WORK:
      return theme.palette.tags.availableToWork.color
    case OPEN:
      return theme.palette.tags.open.color
    case FINISHED:
      return theme.palette.tags.finished.onFinished
    case 7:
      return theme.palette.tags.quickcare.onSecondary
    default:
      return theme.palette.tags.open.color
  }
}

function getIcon(type) {
  switch (type) {
    case 3:
      return (
        <CheckCircleOutlineIcon
          color={getColor(type)}
          sx={{ width: '20px', height: '20px' }}
        />
      )
    case 6:
      return (
        <CheckCircleOutlineIcon
          color={getColor(type)}
          sx={{ width: '20px', height: '20px' }}
        />
      )
    case 7:
      return (
        <CustomAvatar src={'/splash.png'} sx={{ width: '20px', height: '20px' }} />
      )
    default:
      return null
  }
}
function getLabel(type) {
  switch (type) {
    case FILLED:
      return 'Filled'
    case COMPLETED:
      return 'Completed'
    case CANCELLED:
      return 'Cancelled'
    case UNAVAILABLE_TO_WORK:
      return 'UNAVAILABLE TO WORK'
    case AVAILABLE_TO_WORK:
      return 'AVAILABLE TO WORK'
    case FINISHED:
      return 'Finished'
    case OPEN:
      return 'Open'
    case 7:
      return 'Quickcare Educator'
    case 8:
      return 'Private educator for'
    case IN_PROGRESS:
      return 'In Progress'
    case EXPIRED:
      return 'Expired'
    default:
      return 'Open'
  }
}
