
import { ReactComponent as GoodIcon } from "../../../../../assets/good.svg";
import { ReactComponent as OkayIcon } from "../../../../../assets/okay.svg";
import { ReactComponent as PoorIcon } from "../../../../../assets/poor.svg";

import {
    Button,
    Divider,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import React from 'react';
import theme from '../../../../../utils/theme';
import { Space } from '../../../../reusable/Scaffolds';
import useRatingModal from './hooks';

const RATING_OPTIONS = [
  {
    name: 'Poor',
    value: 'downvote',
    icon: PoorIcon,
  },
  {
    name: 'Okay',
    value: 'neutral',
    icon: OkayIcon,
  },
  {
    name: 'Good',
    value: 'upvote',
    icon: GoodIcon,
  }
];


const RatingModalContent = ({ closeModal, orgId, personId, shiftId, reload }) => {
  const { rating, setRating, comment, setComment, onSave, onCancel } = useRatingModal({ closeModal, orgId, personId, shiftId, reload })
  return (
    <Space direction='vertical' gap='8px' style={{ padding: '12px 0', alignItems: 'unset' }} >
      <Typography variant='h7' align='center' sx={{ fontWeight: 500 }} gutterBottom>
        Rate Performance
      </Typography>
      <Divider />
      <Space direction='vertical' gap='12px' style={{ padding: 12 }} >
        <Space style={{ justifyContent: 'space-between', gap: '8px', width: '100%' }}>
            {RATING_OPTIONS.map(({ name, value, icon: Icon, color }) => (
                <Button
                    sx={{
                        color: theme.palette.onSurfaceVariant.main,
                        background: rating === value ? theme.palette.secondary.main : 'none',
                        flex: 1,
                        padding: '8px 0',
                        border: '1px solid #C4C7C7',
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '2px',
                        textTransform: 'none' 
                    }}
                    onClick={() => setRating(value)}
                >
                    <Icon sx={{ fontSize: 24 }} />
                    <Typography sx={{ color: theme.palette.onSurfaceVariant.main }}>{name}</Typography>
                </Button>
            ))}
        </Space>
        <Typography variant='h7' sx={{ fontWeight: 500 }}>
            Comments
        </Typography>
        <TextField
            fullWidth
            multiline
            rows={4}
            placeholder='Comments goes here'
            value={comment}
            onChange={(e) => setComment(e.target.value)}
        />
        <Grid container spacing={2}>
            <Grid item xs={6}>
            <Button
                fullWidth
                variant='contained'
                sx={{
                    background: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    textTransform: 'none',
                    borderRadius: '8px'
                }}
                disabled={!rating} 
                onClick={onSave}
            >
                Save Rating
            </Button>
            </Grid>
            <Grid item xs={6}>
            <Button
                fullWidth
                variant='outlined'
                style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.onSecondary,
                    textTransform: 'none',
                    borderRadius: '8px'
                }}
                onClick={onCancel}
            >
                Cancel
            </Button>
            </Grid>
        </Grid>
      </Space>
    </Space>
  );
};

export default RatingModalContent;
