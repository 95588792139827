import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import theme from "../../../utils/theme";

export function CommonLayoutModal({ open, onClose, children, title }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 10,
          backgroundColor: theme.palette.surfaceContainerLow.main,
          minWidth: "460px",
        },
      }}
    >
      <DialogTitle
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          fontSize: 16,
          fontWeight: 600,
          color: theme.palette.onSurface.main,
        }}
      >
        {title}
      </DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
