import theme from "../../utils/theme";

const modalBoxStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  borderColor: theme.palette.surfaceHigh.main,
  bgcolor: theme.palette.surfaceLow.main,
  boxShadow: 24,
  p: 4,
  borderRadius: '20px',
};

export { modalBoxStyles };