import { FormControl, TextField, Typography } from "@mui/material"
import theme from "../../../utils/theme";
import { DatePickerTextField } from "../TextField";

export const TextFieldWithLabel = ({
  value,
  label,
  onChange,
  onKeyDown,
  helperText = '',
  type = 'text',
  showPassword = false,
  error,
  placeholder = '',
}) => {
  return (
    <>
      <Typography
        variant="caption"
        sx={{
          fontWeight: 600,
          marginBottom: "10px",
          fontSize: 16,
          color: theme.palette.onSurface.main,
        }}
      >
        {label}
      </Typography>
      <FormControl fullWidth>
        {type === "date" ? (
          <DatePickerTextField
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            type={type === "password" && showPassword ? "text" : type}
            placeholder={placeholder}
            variant="outlined"
          />
        ) : (
          <TextField
            value={value}
            onChange={onChange}
            sx={{
              backgroundColor: "white",
              borderRadius: 2,
            }}
            error={error}
            onKeyDown={onKeyDown}
            type={type === "password" && showPassword ? "text" : type}
            placeholder={placeholder}
            variant="outlined"
          />
        )}
        {helperText && (
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.error.main,
              marginTop: "5px",
            }}
          >
            {helperText}
          </Typography>
        )}
      </FormControl>
    </>
  );
}