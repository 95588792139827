import {
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction
} from '@mui/material'
import React, { useContext } from 'react'
import { SpacedRow } from './Scaffolds'
import { M3LabelSmall } from './TextStyles'
import { UserContext } from "../../utils/context";
import AssignmentItem from '../pages/Shifts/components/assignment_item'
import {
  convertToVerbalDate,
  convertTimeWithoutSeconds,
  stringAvatar,
  convertDayMonthYear,
  convertDateWithDayAndYearWithTime,
  upperCaseFirstLetter
} from '../../utils/helper_functions'
import theme from '../../utils/theme'
import { SHIFT_STATES } from '../../utils/constants'
import { CustomAvatar } from '../Layout'

export function ShiftItemList({ params, onClick }) {
  const currentUser = useContext(UserContext)
  const { shift, isOpenTab } = params
  const isShowOrg = currentUser?.is_quickcare_admin
  const isShowEducatorName =
    (shift.educator.id && !shift.assigment_types.startsWith('individual')) ||
    (shift.state !== SHIFT_STATES.AVAILABLE && shift.educator.id)
  const isFilledShift = shift.state === SHIFT_STATES.FILLED || shift.state === SHIFT_STATES.IN_PROGRESS
  const isCancelledShift = shift.state === SHIFT_STATES.CANCELLED || shift.state === SHIFT_STATES.CANCELLATE
  if (!shift || !shift.qualification) {
    console.error('shift', shift)
  }
  if (!shift) return null

  const orgInfoStyle = {
    backgroundColor: '#f0f0f0',
    padding: '8px',
    borderRadius: '4px',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
  }

  const orgInfoContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: isShowOrg ? 'flex-start' : 'center'
  }

  const centreInfoStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
  }

  return (
    <ListItemButton
      sx={{
        backgroundColor: shift.has_unread
          ? theme.palette.background.main
          : null,
        m: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
      key={shift.educator_id ? shift.id * 100 + shift.educator_id : shift.id}
      selected={false}
      onClick={onClick}
    >
      <SpacedRow style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'stretch' }}>
        <div style={orgInfoContainerStyle}>
          {isShowOrg && (
            <div style={orgInfoStyle}>
              <ListItemAvatar>
                <CustomAvatar
                  {...stringAvatar(shift.organisation_name)}
                  src={shift.organisation_logo_url !== null ? shift.organisation_logo_url : null}
                />
              </ListItemAvatar>
              <span>{shift.organisation_name}</span>
            </div>
          )}
          <div style={{ display: 'flex' }}>
            <div style={centreInfoStyle}>
            {isShowOrg ? (
              <div style={centreInfoStyle}>
                <ListItemAvatar>
                  <CustomAvatar
                    {...stringAvatar(shift.centre?.name || 'Centre')}
                    src={shift.centre?.logo_url !== null ? shift.centre?.logo_url : null}
                  />
                </ListItemAvatar>
              </div>
            ) : null}
            </div>
            <ListItemText
              primary={
                <b>{shift.centre?.name}</b>
              }
              secondary={
                <React.Fragment>
                  <span>{`${upperCaseFirstLetter(shift.role)}${upperCaseFirstLetter(shift.role) ? ' - ' : ''
                    }${shift.qualification?.qualification ?? ''}`}</span>
                  <br />
                  <span>
                    {isShowEducatorName
                      ? shift.educator.first_name +
                      (shift.educator.prefered_name
                        ? ' (' + shift.educator.prefered_name + ') '
                        : ' ') +
                      shift.educator.last_name
                      : 'Unfilled'}
                  </span>
                  <br />
                  <span>
                    {convertToVerbalDate(shift.shift_date) +
                      ' | ' +
                      convertTimeWithoutSeconds(shift.shift_start) +
                      ' - ' +
                      convertTimeWithoutSeconds(shift.shift_end)}
                  </span>
                </React.Fragment>
              }
            />
          </div>
        </div>
        {isOpenTab ? <AssignmentItem params={{ shift, isOpenTab }} /> : null}

        <ListItemSecondaryAction>
          <M3LabelSmall>
            {isCancelledShift
              ? 'Cancelled on ' + convertToVerbalDate(shift.cancel_date)
              : isFilledShift
                ? 'Filled on ' +
                convertDateWithDayAndYearWithTime(
                  shift.filled_at ? shift.filled_at : shift.updated_at
                )
                : ''}
          </M3LabelSmall>
        </ListItemSecondaryAction>
      </SpacedRow>
    </ListItemButton>
  )
}

export function EducatorShiftItemList({ params, onClick }) {
  const { shift } = params
  return (
    <ListItemButton
      key={shift.educator_id ? shift.id * 100 + shift.educator_id : shift.id}
      selected={false}
      onClick={onClick}
      alignItems="flex-start"
    >
      {shift.educator.id ? (
        <ListItemAvatar>
          <CustomAvatar
            {...stringAvatar(
              shift.educator.first_name + ' ' + shift.educator.last_name
            )}
            src={
              shift.educator.avatar_url !== null
                ? shift.educator.avatar_url
                : null
            }
          />
        </ListItemAvatar>
      ) : null}
      <SpacedRow>
        <ListItemText
          primary={shift.centre.name}
          secondary={
            <React.Fragment>
              <span>
                {'Start time: ' +
                  convertTimeWithoutSeconds(shift.shift_start) +
                  ' - ' +
                  convertTimeWithoutSeconds(shift.shift_end)}
              </span>
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <M3LabelSmall>{convertDayMonthYear(shift.shift_date)}</M3LabelSmall>
        </ListItemSecondaryAction>
      </SpacedRow>
    </ListItemButton>
  )
}
