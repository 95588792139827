import React, { useState, useMemo } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  OutlinedInput, 
  Typography,
  Box,
  Divider,
  Button,
  CircularProgress,
} from '@mui/material';
import theme from "../../../../utils/theme";
import { isValidPhoneNumber } from "../../../../utils/helper_functions";
import { PaddingBox, Row } from "../../../reusable/Scaffolds";
import { GoogleInput } from '../../../reusable/inputs/google_input';

export default function InviteQuickcareModal ({ open, onClose, handleSaveUser, error, setError }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState({
    address: '',
    lat: '',
    lng: '',
    state: '',
    suburb: '', 
    postcode: '',
    country: ''
  });
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    onClose();
    setPhoneNumber('');
    setEmail('');
    setAddress({
      address: '',
      lat: '',
      lng: '',
      state: '',  
      suburb: '',
      postcode: '',
      country: ''
    });
    setIsLoading('');
    setError('');
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await handleSaveUser(phoneNumber, email, address);
      handleClose();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }
  
  const isValidEmail = (emailInput) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInput);
  };

  const isValid = useMemo(() => {
    return (
      isValidEmail(email) &&
      isValidPhoneNumber(phoneNumber) &&
      address.lat &&
      address.lng
    );
  }, [email, phoneNumber, address]);
  const handlePlaceInput = (e) => {
    setAddress({
      address: e.target.value,
      lat: '',
      lng: ''
    })
  };
    
  const onPlaceSelected = (place) => {
    if (!place?.geometry) {
      setAddress({
        address: '',
        lat: 0,
        lng: 0,
      })  
      return
    }
    const location = place.geometry.location
    const addressComponents = place.address_components
    const state = addressComponents.find((component) => component.types.includes('administrative_area_level_1'))?.short_name
    const suburb = addressComponents.find((component) => component.types.includes('locality'))?.long_name
    const postcode = addressComponents.find((component) => component.types.includes('postal_code'))?.long_name
    const country = addressComponents.find((component) => component.types.includes('country'))?.long_name
    setAddress({
      address: place.formatted_address,
      lat: location.lat(),
      lng: location.lng(),
      state,
      suburb,
      postcode,
      country
    })    
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{  zIndex: 999 }}
        PaperProps={{
          sx: {
            width: "400px",
            padding: 2,
            borderRadius: 2,
           
          },
        }}
      >
        <DialogTitle
          variant="h6"
          component="h2"
          align="center"
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          Invite an Educator
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {error && (
              <Typography color={error ? "error" : null}>{error}</Typography>
            )}
            <Divider />
            <TitleItem title={"Mobile Number"}></TitleItem>
            <OutlinedInput
              fullWidth
              variant="contained"
              placeholder="0498123654"
              margin="normal"
              value={phoneNumber}
              color={isValidPhoneNumber(phoneNumber) ? "success" : "error"}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <TitleItem title={"Email Address"}></TitleItem>
            <OutlinedInput
              fullWidth
              placeholder="name@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              color={isValidEmail(email) ? "success" : "error"}
            />
            <TitleItem title={"Home address "}></TitleItem>
            <GoogleInput
              onPlaceSelected={onPlaceSelected}
              value={address.address}
              onInput={handlePlaceInput}
            />
            <PaddingBox />  
          </Box>
          {isLoading && (
            <Row style={{ marginLeft: "40%" }}>
              <CircularProgress color="primary" />
            </Row>
          )}
        </DialogContent>
        <DialogActions>
          <FullSizedButton
            text="Invite"
            type={"primary"}
            disabled={!isValid}
            onClick={handleSubmit}
          />
          <FullSizedButton
            text="Cancel"
            type={"secondary"}
            onClick={handleClose}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

function TitleItem({ title }) {
  return (
    <Typography
      sx={{
        mt: 1,
        fontSize: "14px",
        textAlign: "left",
        fontWeight: "bold",
      }}
    >
      {title}
    </Typography>
  );
}


function FullSizedButton({ text, onClick, type, icon, disabled, dense }) {
  const colorTheme = {
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.onPrimary,
      "&:hover": {
        backgroundColor: theme.palette.onSurfaceTransparent.main,
      },
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.onSecondary,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    primaryContainer: {
      backgroundColor: theme.palette.primaryContainer.main,
      color: theme.palette.primaryContainer.onPrimary,
      "&:hover": {
        backgroundColor: theme.palette.onSurfaceTransparent.main,
      },
    },
    tertiary: {
      backgroundColor: theme.palette.tertiaryContainer.main,
      color: theme.palette.tertiaryContainer.onTertiary,
      "&:hover": {
        backgroundColor: theme.palette.onSurfaceTransparent.main,
      },
    },
  };
  return (
    <Button
      disableElevation={true}
      variant="contained"
      startIcon={text ? icon : null}
      disableRipple={true}
      onClick={onClick}
      disabled={disabled}
      sx={{
        borderRadius: 2,
        marginY: 2,
        textTransform: "none",
        height: "40px",
        width: !dense ? "100%" : "auto",
        fontSize: "12px",
        ...colorTheme[type],
      }}
    >
      {text ? text : icon}
    </Button>
  );
}