import { createTheme } from '@mui/material/styles';
const palette = {
  primary: {
    main: '#710FEA',
    onPrimary: '#FFFFFF',
  },
  primaryContainer: {
    main: '#E3CFFC',
    onPrimary: '#450990',
  },
  secondary: {
    main: '#A4F4EC',
    onSecondary: '#062D29',
  },
  surfaceContainerLow: {
    main:'#F5F3F3'
  },
  surfaceContainerHigh: {
    main: '#EBE7E7',
  },
  surfaceContainerHighest: {
    main: '#E5E2E1'
  },
  tertiary: {
    main: '#D700E5',
    onTertiary: '#FFFFFF',
  },
  tertiaryContainer: {
    main: '#FDE5FF',
    onTertiary: '#600066',
  },
  onSurfaceVariant: {
    main: '#444748',
  },
  surface: {
    main: '#FCFCFC',
  },
  onSurface: {
    main: '#1D1B20',
  },
  onSurfaceTransparent: {
    main: 'rgba(29, 27, 32, 0.08)'
  },
  outline: {
    main: '#747878',
  },
  outLineVariant: {
    main: '#C4C7C7',
  },
  background: {
    main: '#E3CFFC',
  },
  info: {
    main: '#710FEA',
  },
  success: {
    main: '#64D916',
  },
  warning: {
    main: '#F9D2D3',
  },
  blueJeans: {
    main: '#37ACFF',
    onBlueJeans: '#004475',
  },
  sheenGreen: {
    main: '#64D916',
    onSheenGreen: '#265208',
  },
  goldenPoppy: {
    main: '#FFC300',
    onGoldenPoppy: '#806200',
  },
  error: {
    main: '#E4252E',
    onError: '#FFFFFF',
  },
  errorContainer: {
    main: '#F9D2D3',
    onError: '#2D0607',
  },
  text: {
    main: '#1D1B20',
    secondary: '#444748', // On surface variant
  },
  surfaceLow: {
    main: '#F5F3F3',
    onSurfaceLow: '#1D1B20',
  },
  surfaceHigh: {
    main: '#EBE7E7',
    onSurfaceHigh: '#1D1B20',
  },
};
palette.tags = {
  open: {
    backgroundColor: palette.primary.main,
    color: palette.primary.onPrimary,
  },
  filled: {
    backgroundColor: palette.primaryContainer.main,
    color: palette.primaryContainer.onPrimary,
  },
  inProgress: {
    backgroundColor: palette.blueJeans.main,
    color: palette.blueJeans.onBlueJeans,
  },
  finished: {
    backgroundColor: palette.sheenGreen.main,
    color: palette.sheenGreen.onSheenGreen,
  },
  completed: {
    backgroundColor: palette.goldenPoppy.main,
    color: palette.goldenPoppy.onGoldenPoppy,
  },
  expired: {
    backgroundColor: palette.errorContainer.main,
    color: palette.errorContainer.onError,
  },
  cancelled: {
    backgroundColor: palette.tertiaryContainer.main,
    color: palette.tertiaryContainer.onTertiary,
  },
  quickcare:
  {
    backgroundColor: palette.primaryContainer.main,
    color: palette.primaryContainer.onSecondary,
  },
  unavailableToWork: {
    backgroundColor: palette.tertiaryContainer.main,
    color: palette.tertiaryContainer.onTertiary,
  },
  availableToWork: {
    backgroundColor: palette.tertiary.main,
    color: palette.tertiary.onTertiary,
  },
}
const MuiPickersDay = {
  styleOverrides: {
    today: {
      backgroundColor: palette.primary.main,
      color: palette.primary.onPrimary,
      border: 'none',
      ':focus': {
        backgroundColor: palette.primary.main,
        color: palette.primary.onPrimary
      }
    },
    root: {
      ':hover': {
        backgroundColor: palette.secondary.main,
        color: palette.secondary.onSecondary
      },
      '&.Mui-selected': {
        backgroundColor: palette.primary.main,
        color: palette.primary.onPrimary
      }
    }
  }
}
const MuiPopperPaper = {
  styleOverrides: {
    paper: {
      backgroundColor: palette.surfaceContainerLow.main,
      color: palette.text.main,
    }
  }
}
const MuiListItem = {
  styleOverrides: {
    root: {

    }
  }
}

  
const theme = createTheme({
  colors: {
    text: '#1D1B20',
    backgroundDisabled: '#EBE7E7',
    colorEBF9FF: '#A4F4EC',
    colorB3261E: '#E4252E',
    colorFEF7FF: '#A4F4EC',
    colorB33F5B: '#E3CFFC'
  },
  palette,
  typography: {
    allVariants: { fontFamily: 'Inter' },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: palette.primary.main
          },
          color: palette.onSurfaceVariant.secondary,
          'MuiTabs-indicator': {
            backgroundColor: palette.primary.main
          }
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: palette.tertiary.main,
          color: palette.tertiary.onTertiary
        }
      }
    },
    MuiPickersDay: MuiPickersDay,
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: palette.primary.main,
        },
        root: {
          color: `${palette.primary.onPrimary} !important`,
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: palette.surfaceContainerHighest.main,
            color: palette.text.main
          },
          ':hover': {
            backgroundColor: palette.onSurfaceTransparent.main,
            color: palette.text.main
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        '&.Mui-disabled': {
          opacity: 1,
          backgroundColor: palette.surfaceContainerHighest.main,
        },
        deleteIcon: {
          color: palette.onSurfaceVariant.main,
        }
      }
    },
    MuiListItem,
    MuiPopper: MuiPopperPaper,
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: palette.secondary.main,
            color: palette.secondary.onSecondary
          }
        }
      }
    }
  }
})

export default theme;