import React, { useState, useCallback, useEffect } from "react";
import { Card, CardContent, Typography, Grid, CircularProgress, Box } from "@mui/material";
import { get, post } from "../../../../utils/api";

import { CustomAvatar } from "../../../Layout";
import { useTheme } from "@mui/material/styles";
import { M3TitleLarge } from '../../../reusable/TextStyles'
import OrganisationForm from '../../../../components/forms/OrganisationForm'
import { AddButton } from "../../../reusable/Buttons";

const OrganizationCard = ({ org }) => {
  return (
    <Card variant="outlined" sx={{ mb: 2, p: 2 }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4} container alignItems="center" spacing={1}>
            <Grid item>
              <CustomAvatar
                src={org.logo_url}
                alt="Avatar"
                sx={{ width: 60, height: 60, mt: 2 }}
              />
            </Grid>
            <Grid item>
              <Typography variant="h6" fontWeight="bold" sx={{ fontSize: '15px' }}>{org.name}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2">ABN: {org.abn}</Typography>
            <Typography variant="body2">Email: {org.email}</Typography>
            <Typography variant="body2">Phone: {org.phone_number}</Typography>
            <Typography variant="body2">Address: {org.address}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2">
              <p>Description:</p> {org.description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const OrganizationList = () => {
  const [organisations, setOrganisations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [newOrg, setNewOrg] = useState(() => {
    return {
      abn: "",
      description: "",
      name: "",
      email: "",
      phone_number: "",
      address: {},
      logo: ""
    };
  });

  const fetchOrganizations = useCallback(() => {
    setIsLoading(true);
    get(`/admin/organisations`)
      .then((res) => {
        setOrganisations(res);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  async function handleCreateOrUpdate() {
    try {
      await post('/admin/organisations', newOrg);
      fetchOrganizations();
      onClearForm();
    } catch (error) {
      console.error(error);
    }
  }

  const onClearForm = () => {
    setNewOrg({
      abn: "",
      description: "",
      name: "",
      email: "",
      phone_number: "",
      address: {},
      logo: ""
    })
  }

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <M3TitleLarge>Organisations</M3TitleLarge>
        <AddButton
          onClick={() => setIsModalOpen(true)}
          text="Create Organisation"
          theme={theme}
        />
      </Box>
      {isLoading ? (
        <CircularProgress color="primary" />
      ) : (
        <Box sx={{ borderBottom: 1, borderColor: theme.palette.outline.main }}>
          {organisations.map((org, index) => (
            <OrganizationCard key={index} org={org} />
          ))}
        </Box>
      )}
      <OrganisationForm
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        newOrg={newOrg}
        setNewOrg={setNewOrg}
        error={error}
        setError={setError}
        setOpen={setIsModalOpen}
        handleCreateOrUpdate={handleCreateOrUpdate}
        onClearForm={onClearForm}
      />
    </div>
  );
};

export default OrganizationList;
