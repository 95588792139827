import { useCallback, useState } from "react";
import { post } from "../../../../../utils/api";
import { useAlert } from "../../../../reusable/alert/alert_provider";

const useRatingModal = ({ closeModal, orgId, personId, shiftId, reload }) => {
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState("");
  const { showAlert } = useAlert();

  const onSave = useCallback(async () => {
    try {
        await post(`/manage/organisation/${orgId}/ratings`, {
            "person_id": personId,
            "rating": rating,
            "shift_id": shiftId,
            "comment": comment
        })
        showAlert(`Save rating success`, 'success')
    } catch (error) {
        showAlert('Error while saving rating', 'error')
    }
    closeModal()
    reload()
  }, [closeModal, reload, orgId, personId, rating, shiftId, comment, showAlert])

  const onCancel = useCallback(() => {
    closeModal()
  }, [closeModal])

  return { rating, setRating, comment, setComment, onSave, onCancel }
}

export default useRatingModal;