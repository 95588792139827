import {
  Button,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  Box,
  CircularProgress,
  Menu,
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled as muiStyled } from "@mui/material";
import { withTheme } from "@mui/styles";
import PlusIcon from "@mui/icons-material/Add";
import { M3Body, M3BodyMedium } from "./TextStyles";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import theme from "../../utils/theme";
import { useState } from "react";
import { PaddingBox } from "./Scaffolds";

export function DeactiveAndActiveButton({ onClick, values, text, isDisabled }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isDeactive = text === 'Deactivate';
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (id) => {
    setAnchorEl(null);
    if(id === null) return;
    onClick(id);
  };
  if(values.length === 0) return null;
  return (
    <div style={{
      marginRight: 16,
    }} >
      <Button
        size="small"
        variant="contained"
        disabled={isDisabled}
        sx={{ 
          borderRadius: 28, 
          textTransform: 'none',
          backgroundColor: isDeactive? theme.palette.tertiaryContainer.main : theme.palette.success.main,
          color: isDeactive? theme.palette.tertiaryContainer.onTertiary : theme.palette.success.onSuccess,
          /// For the hover effect
          '&:hover': {
            backgroundColor: isDeactive? theme.palette.tertiaryContainer.main : theme.palette.success.main,
            opacity: 0.8,
          }
         }}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        {text}
      </Button>
      <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
      >
        {
          values.map((value) => (
            <MenuItem key={value.id} onClick={() => {
              handleClose(value.id);
            }}>
              {value.name}
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  )
}
export function AddButton({ onClick, text, disabled = false }) {
  return (
    <CreateButton
      variant="contained"
      color="info"
      disabled={disabled}
      startIcon={<PlusIcon />}
      onClick={onClick}
    >
      <M3Body>{text}</M3Body>
    </CreateButton>
  );
}
export function PrimaryButton({ onClick, text, isDisabled = false, isLoading = false }) {
  return (
    <CreateButton
      disabled={isDisabled}
      variant="contained"
      type="submit"
      onClick={onClick}
      color="info"
    >
      {isLoading ? (
        <CircularProgress size={24} color="white"></CircularProgress>
      ) : (
        <M3Body>{text}</M3Body>
      )}
    </CreateButton>
  )
}

export function SuccessButton({ onClick, text, isDisabled }) {
  return (
    <SuccessButtonStyled
      onClick={onClick}
      variant="contained"
      disabled={isDisabled}
    >
      {text}
    </SuccessButtonStyled>
  );
}

export function RemoveButton({ onClick, text, isDisabled }) {
  return (
    <EndButton
      onClick={onClick}
      variant="contained"
      disabled={isDisabled}
    >
      <M3Body>{text}</M3Body>
    </EndButton>
  )
}
export function CloseButton({ onClick, text }) {
  return (
    <CancelButton
      onClick={onClick}
      variant="contained"
    >
      <M3Body>{text}</M3Body>
    </CancelButton>
  )
}

const CancelButton = muiStyled(withTheme(Button))`
    text-transform: none;
    padding: 8px 16px;
      border-radius: 28px;
    background-color: ${(props) => props.theme.palette.secondary.main} !important;
    color: ${(props) => props.theme.palette.secondary.onSecondary} !important;
    &:hover {
      opacity: 0.8;
    }
    `;

const EndButton = muiStyled(withTheme(Button))`
    text-transform: none;
    border-radius: 28px;
    padding: 8px 16px;
    background-color: ${(props) => props.theme.palette.tertiaryContainer.main} !important;
    color: ${(props) => props.theme.palette.tertiaryContainer.onTertiary} !important;
    &:hover {
      opacity: 0.8;
    }
    `;
const CreateButton = muiStyled(withTheme(Button))`
    text-transform: none;
    border-radius: 28px;
    padding: 8px 16px;
    &:hover {
      opacity: 0.8;
    }
`;
const SuccessButtonStyled = muiStyled(withTheme(Button))`
  text-transform: none;
  border-radius: 28px;
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  &:hover {
    background-color: darkgreen;
    opacity: 0.8;
  }
`;

const SelectCustom = muiStyled(withTheme(Select))`
    height: 32px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    &.Mui-disabled .MuiOutlinedInput-notchedOutline {
        border-color:rgba(0, 0, 0, 0.2);
        &:hover {
            border-color:rgba(0, 0, 0, 0.8)
        }
    }
    &.MuiInputBase-root .Mui-disabled {
        -webkit-text-fill-color: rgba(0, 0, 0, 0.8);
         color: rgba(0, 0, 0, 0.8)
    }
`;

export function FilterButton({
  currentValue,
  onChange,
  options,
  placeholder,
  onClick,
  isDisabled = false,
  fullWidth = false,
  customMargin
}) {
  return (
    <SelectCustom
      value={currentValue}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      onChange={onChange}
      onClick={onClick}
      autoWidth
      style={{ borderRadius: "8px", margin: customMargin || "0 0 0 16px" }}
      disabled={isDisabled}
      placeholder={placeholder}
      fullWidth={fullWidth}
      sx={{
        borderRadius: 2,
      }}
    >
      <MenuItem disabled value={""}>
        {placeholder}
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id} dense>
          {option.name}
        </MenuItem>
      ))}
    </SelectCustom>
  );
}

export function FilterButtonMultiple({
  currentValue,
  onChange,
  options,
  placeholder,
  onClick,
  isDisabled = false,
  fullWidth = false,
  label = 'location',
  customMargin
}) {
  const renderValue = (selected) => {
    if (selected.length === 0) return placeholder
    if (!label) return `${selected.length} selected`
    return `${selected.length} ${label}${selected.length > 1 ? 's' : ''} selected`;
  };

  return (
    <SelectCustom
      value={currentValue}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      onChange={onChange}
      onClick={onClick}
      renderValue={renderValue}
      autoWidth
      multiple
      fullWidth={fullWidth}
      style={{ 
        borderRadius: "8px", 
        margin: customMargin || "0 0 0 16px",
        backgroundColor: isDisabled ? "rgba(0, 0, 0, 0.12)" : "inherit"
      }}
      disabled={isDisabled}
      placeholder={placeholder}
      sx={{
        borderRadius: 2,
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          <Checkbox checked={currentValue.indexOf(option.id) > -1} />
          <ListItemText primary={option.name} />
        </MenuItem>
      ))}
    </SelectCustom>
  );
}

export function FilterEmpty({
  currentValue,
  placeholder,
  onClick,
  isDisabled = false,
}) {
  return (
    <SelectCustom
      value={currentValue}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      onClick={onClick}
      autoWidth
      style={{ borderRadius: "8px" }}
      disabled={isDisabled}
      placeholder={placeholder}
      sx={{
        borderRadius: 2,
      }}
    >
      <MenuItem disabled value={""}>
        {placeholder}
      </MenuItem>
    </SelectCustom>
  );
}

export const BackButton = ({ title, onClick }) => {
  return (
    <Button
      startIcon={<KeyboardBackspaceIcon />}
      onClick={onClick}
      sx={{
        textTransform: "none",
        fontSize: "14px",
        fontWeight: 500,
        color: theme.palette.info.main,
      }}
    >
      {title}
    </Button>
  );
};

export function DropDownButton({
  currentValue,
  onChange,
  options,
  placeholder,
  onClick,
  isDisabled = false,
}) {
  return (
    <FormControl
      fullWidth
      variant={isDisabled ? "filled" : "outlined"}
    >
      <Select
        value={currentValue}
        onChange={onChange}
        onClick={onClick}
        disabled={isDisabled}
        placeholder={placeholder}
        sx={{
          backgroundColor: "white",
        }}
      >
        {options}
      </Select>
    </FormControl>
  );
}



export function ApprovalCheckbox ({ isChecked = false, onChange, content }){
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Checkbox
        checked={isChecked}
        onChange={onChange}
        sx={{ color: isChecked ? theme.palette.primary.main : '' }}
      />
       <M3BodyMedium>{content}</M3BodyMedium>
    </Box>
  );
};
/**
 * A customizable full-width button component with different color themes
 * 
 * @component
 * @param {Object} props - Component props
 * @param {string} [props.text] - Button text content
 * @param {Function} props.onClick - Click event handler
 * @param {('primary'|'secondary'|'primaryContainer'|'tertiary')} props.type - Button style theme
 * @param {React.ReactNode} [props.icon] - Icon to display inside the button
 * @param {boolean} [props.disabled=false] - Whether the button is disabled
 * @param {boolean} [props.dense=false] - If true, button has auto width instead of full width
 * 
 * @example
 * // Primary button with text
 * <FullSizedButton 
 *   text="Save Changes" 
 *   onClick={handleSave} 
 *   type="primary" 
 * />
 * 
 * @example
 * // Icon-only tertiary button
 * <FullSizedButton 
 *   icon={<DeleteIcon />}
 *   onClick={handleDelete}
 *   type="tertiary"
 *   dense={true}
 * />
 * 
 * @returns {React.ReactElement} Rendered button component
 */
export function FullSizedButton({ text, onClick, type, icon, disabled, dense, isLoading }) {
  const colorTheme = {
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.onPrimary,
      "&:hover": {
        backgroundColor: theme.palette.onSurfaceTransparent.main,
      },
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.onSecondary,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    primaryContainer: {
      backgroundColor: theme.palette.primaryContainer.main,
      color: theme.palette.primaryContainer.onPrimary,
      "&:hover": {
        backgroundColor: theme.palette.onSurfaceTransparent.main,
      },
    },
    tertiary: {
      backgroundColor: theme.palette.tertiaryContainer.main,
      color: theme.palette.tertiaryContainer.onTertiary,
      "&:hover": {
        backgroundColor: theme.palette.onSurfaceTransparent.main,
      },
    },
  };
  const iconComponent = icon ? <div style={{ fill: colorTheme[type].color, marginTop: '8px' }}>{icon}</div> : null;
  return (
    <Button
      variant="contained"
      disableRipple={true}
      startIcon={text ? iconComponent : null}
      onClick={onClick}
      disabled={isLoading || disabled}
      sx={{
        borderRadius: 2,
        marginY: 2,
        textTransform: "none",
        height: "40px",
        width: !dense ? "100%" : "auto",
        fontWeight: 500,
        fontSize: "16px",
        ...colorTheme[type],
      }}
    >
      <>
        {isLoading ? (
          <>
            <CircularProgress
              size={16}
              sx={{
                color: "gray",
              }}
            ></CircularProgress>
            <PaddingBox />
          </>
        ) : null}

        {text ? text : iconComponent}
      </>
    </Button>
  );
}
