
import { Box, Divider, styled as muiStyled, Tab, Tabs } from '@mui/material';
import { M3LabelSmall } from './TextStyles';

export const CustomTabs = muiStyled(Tabs)`
    .MuiTabs-indicator {
        background-color: ${props => props.theme.palette.info.main};
    }
`;

export const CustomTab = muiStyled(Tab)`
    text-transform: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    min-width: 17%;
    letter-spacing: 0.1px;
`;

export const PaddingBox = muiStyled('div')`
    width: 16px;
    height: 16px;
`;
export const PaddingBoxSmall = muiStyled('div')`
    width: 8px;
    height: 8px;
`

export const SpacedRow = muiStyled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Row = muiStyled('div')`
    display: flex;
    align-items: center;
`;

export const Space = ({ children, direction = 'horizontal', gap = 0, style = {} }) => {
  return (
    <Box
      display="flex"
      flexDirection={direction === 'vertical' ? 'column' : 'row'}
      gap={gap}
      style={{alignItems: 'center', ...style}}
    >
      {children}
    </Box>
  );
};

export function LabelDivider({label}) {
    return (
        <div>
            <Divider textAlign="left">
                <M3LabelSmall
                    variant="caption"
                    sx={{ color: "text.secondary" }}
                    >
                    {label}
                </M3LabelSmall>
            </Divider>
        </div>
    )
}

export const Column = muiStyled('div')`
    display: flex;
    flex-direction: column;
`