import React, { useContext, useState } from 'react'
import { PaddingBox } from '../reusable/Scaffolds'
import { formatToLocalPhoneNumber } from '../../utils/helper_functions'
import { MileStoneRow, QualificationItem } from "../reusable/QualificationItem"
import { qualificationLevelId } from "../..//utils/constants"
import { DropDownButton, FullSizedButton } from '../reusable/Buttons'
import { Row } from '../Layout'
import { CommonLayoutModal } from '../reusable/modals/common_modal_layout'
import { TextFieldWithLabel } from '../reusable/inputs/normal_text_field'
import { post } from '../../utils/api'
import { OrgContext } from '../../utils/context'
import { Box, MenuItem, Typography } from '@mui/material'
import theme from '../../utils/theme'
const defaultUser = {
  email: '',
  qualification: 0,
  phone_number: '',
  latest_qualification_date: null,
  ect_level: 'L1',
  milestone_passed: false,
}
const defaultQualificationList = [
  {
    value: qualificationLevelId.CERT3,
    name: 'Certificate III',
  },
  {
    value: qualificationLevelId.DIPLOMA,
    name: 'Diploma',
  },
  {
    value: qualificationLevelId.ECT,
    name: 'ECT',
  },
  {
    value: qualificationLevelId.UNQUALIFIED,
    name: 'Unqualified',
  },
  {
    value: qualificationLevelId.CERT_TOWARD,
    name: 'WT Cert 3',
  },
  {
    value: qualificationLevelId.DIPLOMA_TOWARD,
    name: 'WT Diploma',
  },
  {
    value: qualificationLevelId.ECT_TOWARRD,
    name: 'WT ECT',
  },
]
const ECTLevel = [
  {
    value: 'L1',
    name: 'L1',
  },
  {
    value: 'L2',
    name: 'L2',
  },
  {
    value: 'L3',
    name: 'L3',
  },
  {
    value: 'L4',
    name: 'L4',
  },
  {
    value: 'L5',
    name: 'L5',
  },
  {
    value: 'L6',
    name: 'L6',
  },
]
const OrgEducatorInviteForm = ({
  open,
  setOpen,
  setAlert
}) => {
  const org = useContext(OrgContext)
  const org_id = org ? org.organisation_id : 0
  const [currentUser, setCurrentUser] = useState(defaultUser)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const changeQualification = (value) => {
    setCurrentUser((prev) => ({
      ...prev,
      qualification: value,
    }))
  }
  const handleAddUser = () => {
    setIsLoading(true)
    post(`/manage/organisations/${org_id}/invite-educator`, currentUser)
      .then((res) => {
        resetUserAndClose();
        setError(null);
        setIsLoading(false);
        setAlert('Educator invited successfully');
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
        setAlert(error.message, 'error');
      });
  }
  const resetUserAndClose = () => {
    setCurrentUser(defaultUser);
    setOpen(false);
  }
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const isValidEmail = (email) => {
    return emailRegex.test(email);
  }
  const isValidPhoneNumber = (phoneNumber) => {
    return phoneNumber.length === 10;
  }
  const disableButton = () => {
    return (
      !isValidPhoneNumber(currentUser.phone_number) ||
      (!currentUser.latest_qualification_date &&
        currentUser.qualification !== qualificationLevelId.UNQUALIFIED) ||
      (!isValidEmail(currentUser.email) && currentUser.email !== "")
    );
  }
  return (
    <CommonLayoutModal
      open={open}
      onClose={() => setOpen(false)}
      title="Invite Educator"
    >
      <TextFieldWithLabel
        label="Mobile Number"
        value={currentUser.phone_number}
        type="tel"
        placeholder="+61 498 123 654"
        onChange={(e) => {
          const phoneNumber = formatToLocalPhoneNumber(e.target.value);
          setCurrentUser((prev) => ({
            ...prev,
            phone_number: phoneNumber,
          }));
        }}
        error={
          currentUser.phone_number &&
          !isValidPhoneNumber(currentUser.phone_number)
        }
        helperText={
          currentUser.phone_number &&
          !isValidPhoneNumber(currentUser.phone_number) &&
          "Please enter a valid phone number"
        }
      />
      <PaddingBox />
      <TextFieldWithLabel
        label="Email Address"
        type="email"
        value={currentUser.email}
        placeholder="name@email.com"
        onChange={(e) => {
          setCurrentUser((prev) => ({
            ...prev,
            email: e.target.value,
          }));
        }}
        error={currentUser.email && !isValidEmail(currentUser.email)}
        helperText={
          currentUser.email &&
          !isValidEmail(currentUser.email) &&
          "Please enter a valid email"
        }
      />
      <PaddingBox />
      {[
        defaultQualificationList.slice(0, 4),
        defaultQualificationList.slice(4, 7),
      ].map((list) => (
        <>
          <Row>
            {list.map((item) => (
              <QualificationItem
                key={item.value}
                item={item}
                selected={currentUser.qualification === item.value}
                onClick={() => changeQualification(item.value)}
              />
            ))}
          </Row>
          <PaddingBox />
        </>
      ))}
      {currentUser.qualification !== qualificationLevelId.UNQUALIFIED && (
        <TextFieldWithLabel
          label="Qualification Date"
          type="date"
          value={currentUser.latest_qualification_date}
          onChange={(e) => {
            setCurrentUser((prev) => ({
              ...prev,
              latest_qualification_date: e.target.value,
            }));
          }}
        />
      )}
      {currentUser.qualification === qualificationLevelId.ECT_TOWARRD && (
        <>
          <PaddingBox />
          <MileStoneRow
            passedMilestone={currentUser.milestone_passed}
            onChange={(value) => {
              setCurrentUser((prev) => ({
                ...prev,
                milestone_passed: value,
              }));
            }}
          />
        </>
      )}
      {currentUser.qualification === qualificationLevelId.ECT && (
        <>
          <PaddingBox />
          <Typography
            variant="caption"
            sx={{
              fontWeight: 600,
              marginBottom: "10px",
              fontSize: 16,
              color: theme.palette.onSurface.main,
            }}
          >
            {"ECT Level"}
          </Typography>
          <DropDownButton
            placeholder="ECT Level"
            currentValue={currentUser.ect_level}
            onChange={(e) => {
              console.log(e);
              setCurrentUser((prev) => ({
                ...prev,
                ect_level: e.target.value,
              }));
            }}
            options={ECTLevel.map((item) => (
              <MenuItem key={item.value} value={item.value} dense>
                {item.name}
              </MenuItem>
            ))}
          />
        </>
      )}
      <Box
        sx={{
          width: "400px",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: "error.main",
            marginTop: "5px",
          }}
        >
          {error}
        </Typography>
      </Box>

      <PaddingBox />
      <Row>
        <FullSizedButton
          type="primary"
          disabled={disableButton()}
          onClick={handleAddUser}
          isLoading={isLoading}
          text={"Invite"}
        />
        <PaddingBox />
        <FullSizedButton
          isLoading={isLoading}
          type="secondary"
          onClick={resetUserAndClose}
          text={"Cancel"}
        />
      </Row>
    </CommonLayoutModal>
  );
}

export default OrgEducatorInviteForm
