
import React from 'react'
import { Select, MenuItem, InputLabel, Grid, FormControl, Card, Typography, Chip } from "@mui/material"
import { DropDownButton }  from "./Buttons";
import theme from '../../utils/theme';
import { ReactComponent as IssueLogo } from "../../assets/issue-logo.svg";
import { ReactComponent as Cert3Logo } from "../../assets/cert3-logo.svg";
import { ReactComponent as DiplomaLogo } from "../../assets/diploma-logo.svg";
import { ReactComponent as EctLogo } from "../../assets/ect-logo.svg";
import { qualificationLevelId } from '../../utils/constants';
import { Row } from './Scaffolds';

export function PercentCompleteItem({ xs, milestone_passed, onChange, percentCompletes }) {
  return (
    <Grid item xs={xs}>
      <FormControl fullWidth>
        <InputLabel id="percent-complete-label">
          % Complete
        </InputLabel>
        <Select
          id="percentComplete"
          value={percentCompletes.length > 0 ? milestone_passed : ''}
          label="% Complete"
          variant="outlined"
          onChange={onChange}
          >
          {percentCompletes.map((option) => (
            <MenuItem key={option.id} value={option.value} dense>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )
}

export function MileStoneRow({ passedMilestone, onChange  }) {
  return (
    <>
      <Typography
        variant="caption"
        sx={{
          fontWeight: 600,
          marginBottom: "10px",
          fontSize: 16,
          color: theme.palette.onSurface.main,
        }}
      >
        {"% Complete"}
      </Typography>
      <Row>
        <MileStoneChip
          label={"<30%"}
          selected={passedMilestone !== null && passedMilestone === false}
          onClick={() => {
            onChange(false);
          }}
        ></MileStoneChip>
        <MileStoneChip
          label={">30%"}
          selected={passedMilestone !== null && passedMilestone === true}
          onClick={() => {
            onChange(true);
          }}
        ></MileStoneChip>
      </Row>
    </>
  );
}
export function PreviousQualificationItem({ xs, currentValue, onChange, options}) {
  return (
    <Grid item xs={xs}>
      <DropDownButton
        placeholder="Previous qualification"
        currentValue={currentValue}
        onChange={onChange}
        options={options}
      />
  </Grid>
  )
}
function MileStoneChip({ label, onClick, selected }) {
  return (
    <Chip
      variant={selected ? "contained" : "outlined"}
      label={label}
      sx={{
        marginRight: 1,
        borderRadius: "10px",
        cursor: "pointer",
        backgroundColor: selected
          ? theme.palette.primaryContainer.main
          : "white",
        border: `1px solid ${
          selected
            ? theme.palette.primaryContainer.onPrimary
            : theme.palette.surface.main
        }`,
        color: selected
          ? theme.palette.primaryContainer.onPrimary
          : theme.palette.text.primary,
      }}
      onClick={onClick}
    ></Chip>
  );
}

export function EctLevelItem ({ xs, currentValue, onChange, options}) {
  return (
    <Grid item xs={xs}>
      <DropDownButton
        placeholder={'ECT level'}
        currentValue={currentValue}
        onChange={onChange}
        options={options}
      />
    </Grid>
  )
}

export function QualificationItem({ item, onClick, selected }) {
  const logo = getLogoComponent(
    item,
    selected
  );
  return (
    <Grid item xs={3}>
      <Card
        onClick={onClick}
        variant="outlined"
        sx={{
          padding: 1,
          borderRadius: 2,
          marginRight: "8px",
          cursor: "pointer",
          backgroundColor: selected
            ? theme.palette.primaryContainer.main
            : "white",
          color: selected
            ? theme.palette.primaryContainer.onPrimary
            : theme.palette.text.primary,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {logo}
          <Typography
            sx={{
              fontSize: "12px",
              marginTop: "2px",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            {item.name}
          </Typography>
        </div>
      </Card>
    </Grid>
  );
}

const getLogoComponent = (qualification, selected) => {
  const logoStyle = {
    fill: selected
      ? theme.palette.primaryContainer.onPrimary
      : theme.palette.text.main, 
    width: "30px",
    height: "30px",
  };
  if (
    qualification.value === qualificationLevelId.CERT3 ||
    qualification.value === qualificationLevelId.CERT_TOWARD
  ) {
    return <Cert3Logo style={logoStyle} />;
  } else if (
    qualification.value === qualificationLevelId.DIPLOMA ||
    qualification.value === qualificationLevelId.DIPLOMA_TOWARD
  ) {
    return <DiplomaLogo style={logoStyle} />;
  } else if (
    qualification.value === qualificationLevelId.ECT ||
    qualification.value === qualificationLevelId.ECT_TOWARRD
  ) {
    return <EctLogo style={logoStyle} />;
  } else {
    return <IssueLogo style={logoStyle} />;
  }
};