import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { get } from "../../../../utils/api";
import { assignmentGroupType, bonusList } from "../../../../utils/constants";
import {
  convertYearMonthDay,
  isFavoriteAssigned,
  isIndividualAssigned,
  upperCaseFirstLetter,
} from "../../../../utils/helper_functions";
import theme from "../../../../utils/theme";
import {
  compareTimePickerValues,
  compareTwoDates,
  convertDateTimeToServerFormat,
  get2MonthsLaterDate,
  isDateBeforeCurrentDate,
  isEndDateIsTwoMonthsLaterAfterStartDate,
  isValidDate,
} from "../../../../utils/time";
import { CloseButton, DropDownButton } from "../../../reusable/Buttons";
import { style, styleDialog } from "../../../reusable/Modal";
import { PaddingBox, Row, SpacedRow } from "../../../reusable/Scaffolds";
import {
  CommonTextField,
  DatePickerTextField,
  TimeRangePicker,
} from "../../../reusable/TextField";
import { M3BodyMedium } from "../../../reusable/TextStyles";
import { useFetchAssignOption } from "../../../reusable/hooks/useFetchAssignOption";
import { ReactComponent as EctLogo } from "../../../../assets/ect-logo.svg";
import { ReactComponent as LeaderLogo } from "../../../../assets/leader-logo.svg";
import { ReactComponent as AssistantLogo } from "../../../../assets/assistant-logo.svg";

const rolesData = {
  ASSISTANT: "assistant",
  LEADER: "leader",
  TEACHER: "teacher",
};

const qualificationData = {
  ANY: -1,
  CERT3: 1,
  DIPLOMA: 3,
  ECT: 5,
  ASSISTANT: 13,
};
export function CreateShiftModal({
  open,
  onCancel,
  onClickCreate,
  centres,
  qualifications,
  orgId,
  orgName,
}) {
  const today = new Date();
  const [educatorPool, setEducatorPool] = React.useState("");
  const [qualificationOptions, setQualificationOptions] =
    React.useState(qualifications);
  const [roleOptions, setRoleOptions] = React.useState([]);
  const [favoriteDisable, setFavoriteDisable] = React.useState(false);
  const [currentRole, setCurrentRole] = React.useState(null);
  const [selectedEducator, setSelectedEducator] = React.useState(null);
  const [allOrgEducators, setAllOrgEducators] = React.useState([]);
  const { assignOptions, fetchAssignOptions, setAssignOptions } =
    useFetchAssignOption();

  const educatorPoolRef = useRef(educatorPool);

  const fetchOrgEducators = useCallback(
    async (start_date, end_date, qualification) => {
      const qualification_list = !qualification
        ? qualification
        : `${qualification},${qualification + 1}`;
      const is_favorite =
        educatorPoolRef.current === assignmentGroupType.favorite ||
        !educatorPool;
      const params = {
        start_date,
        end_date,
        qualification_list,
        include_private_educator: true,
        is_favorite,
        role:  currentRole.code,
      };
      const res = await get(
        `/manage/organisations/${orgId}/educators`,
        null,
        params
      );
      setAllOrgEducators(res);
    },
    [educatorPool, currentRole, orgId]
  );

  const fetchOrgFavoriteEducators = useCallback(
    async (start_date, end_date, qualification) => {
      if (!start_date || !end_date) {
        return;
      }
      const qualification_list = !qualification
        ? qualification
        : `${qualification},${qualification + 1}`;
      const params = {
        start_date,
        end_date,
        qualification_list,
        include_private_educator: true,
        is_favorite: true,
        role: currentRole.code,
      };
      const res = await get(
        `/manage/organisations/${orgId}/educators`,
        null,
        params
      );
      if (res.length === 0) {
        setFavoriteDisable(true);
        if (educatorPool === assignmentGroupType.favorite) {
          setEducatorPool("");
        }
      } else {
        setFavoriteDisable(false);
      }
    },
    [educatorPool, orgId, currentRole]
  );

  const changeEducatorPool = (event) => {
    setEducatorPool(event.target.value);
    if (
      event.target.value !== assignmentGroupType.favorite &&
      event.target.value !== assignmentGroupType.invidualAssigned
    ) {
      setSelectedEducator(null);
    }
  };
  const [bonus, setBonus] = React.useState("");
  const changeBonus = (event) => {
    setBonus(event.target.value);
  };
  const [centre, setCentre] = React.useState("");

  const fetchRoleOption = useCallback(async () => {
    try {
      const res = await get(`/manage/roles`);
      setRoleOptions(res.roleOptions);
    } catch (error) {}
  }, []);

  const changeCentre = (event) => {
    fetchAssignOptions(orgId, event.target.value);
    setCentre(event.target.value);
  };
  const changeRole = (role) => {
    setCurrentRole(role);
    if (role.code === rolesData.ASSISTANT) {
      setQualificationOptions(
        qualifications.filter(
          (item) =>
            item.id === qualificationData.CERT3 ||
            item.id === qualificationData.DIPLOMA
        )
      );
      if (qualification !== qualificationData.ASSISTANT) {
        setQualification(qualificationData.ASSISTANT);
      }
    } else if (role.code === rolesData.LEADER) {
      setQualificationOptions(
        qualifications.filter((item) => item.id === qualificationData.DIPLOMA)
      );
      if (qualification !== qualificationData.DIPLOMA) {
        setQualification(qualificationData.DIPLOMA);
      }
    } else {
      setQualificationOptions(
        qualifications.filter((item) => item.id === qualificationData.ECT)
      );
      if (qualification !== qualificationData.ECT) {
        setQualification(qualificationData.ECT);
      }
    }
  };
 
  const [qualification, setQualification] = React.useState("");
  const changeQualification = (qualification) => {
    setQualification(qualification);
    setSelectedEducator(null);
    fetchOrgEducators(startDate, endDate, qualification);
    handleRoleField(qualification);
  };
  const handleRoleField = (qualificationValue) => {
    switch (qualificationValue) {
      case qualificationData.ANY:
        setQualificationOptions([]);
        setCurrentRole(null);
        break;
      default:
    }
  };

  const [description, setDescription] = React.useState("");
  const changeDescription = (event) => {
    setDescription(event.target.value);
  };

  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [startTime, setStartTime] = React.useState("08:00:00");
  const [endTime, setEndTime] = React.useState("16:00:00");

  useEffect(() => {
    educatorPoolRef.current = educatorPool;
    if (
      isIndividualAssigned(educatorPool) ||
      isFavoriteAssigned(educatorPool)
    ) {
      fetchOrgEducators(startDate, endDate, qualification);
    }
    fetchOrgFavoriteEducators(startDate, endDate, qualification);
  }, [
    educatorPool,
    endDate,
    fetchOrgEducators,
    fetchOrgFavoriteEducators,
    qualification,
    startDate,
  ]);

  function setTimeRange(newValue) {
    setStartTime(convertDateTimeToServerFormat(newValue[0]));
    setEndTime(convertDateTimeToServerFormat(newValue[1]));
  }

  function getShiftDates(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);
    let endD = new Date(endDate);
    while (currentDate <= endD) {
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        dates.push(convertYearMonthDay(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  function handlePostShift() {
    const validated = validateFormCreate();
    if (!validated) {
      return;
    }
    const updateShift = {
      educator_id: selectedEducator ? selectedEducator.id : null,
      shift_dates: getShiftDates(startDate, endDate),
      shift_start: startTime,
      shift_end: endTime,
      assigment_types: educatorPool,
      bonus: bonus,
      qualification: qualification,
      centre_id: centre,
      active: "true",
      description: description,
      role: currentRole.code,
    };
    if (updateShift.educator_id === null) {
      delete updateShift.educator_id;
    }
    onClickCreate(updateShift);
  }

  const isEndDateTooLate = isEndDateIsTwoMonthsLaterAfterStartDate(
    new Date(),
    endDate
  );
  const isStartDateTooLate = isEndDateIsTwoMonthsLaterAfterStartDate(
    new Date(),
    startDate
  );
  const isEndDateEarlierThanToday = isDateBeforeCurrentDate(endDate);
  const isStartDateEarlierThanToday = isDateBeforeCurrentDate(startDate);
  const dateTimeError =
    isEndDateTooLate || isStartDateTooLate
      ? "Cannot choose a date more than 2 months away."
      : isEndDateEarlierThanToday || isStartDateEarlierThanToday
      ? "Cannot choose a date earlier than today."
      : "";
  const endDateRestriction = get2MonthsLaterDate(new Date());
  function validateFormCreate() {
    const validateEndTimeSmallerThanStartTime =
      compareTimePickerValues(startTime, endTime) === -1;
    const validateUserId = individualOrFavoriteAssinged
      ? selectedEducator !== null
      : true;
    const validQualificationRole =
      qualification && (qualification === -1 || !!currentRole);
    return (
      dateTimeError === "" &&
      !isEndDateTooLate &&
      centre !== "" &&
      validQualificationRole &&
      startDate !== "" &&
      endDate !== "" &&
      startTime !== "" &&
      endTime !== "" &&
      validateEndTimeSmallerThanStartTime &&
      validateUserId &&
      educatorPool !== ""
    );
  }

  const clearFormCreate = useCallback(
    (centres) => {
      setSelectedEducator(null);
      if (centres.length !== 1) {
        setCentre("");
        setAssignOptions([]);
      }
      setStartDate("");
      setEndDate("");
      setStartTime("08:00:00");
      setEndTime("16:00:00");
      setBonus("");
      setEducatorPool("");
      setDescription("");
      setQualificationOptions(qualifications);
      if (centres.length === 1) {
        fetchAssignOptions(orgId, centres[0].id.toString());
        setCentre(centres[0].id.toString());
      }
    },
    [orgId, qualifications, setAssignOptions, fetchAssignOptions]
  );

  const updateDateFilter = (isStartDate, date) => {
    _.debounce(() => {
      if (!isValidDate(date)) {
        return;
      }

      if (isStartDate) {
        setStartDate(date);
        if (!endDate || compareTwoDates(date, endDate) === 1) {
          setEndDate(date);
        }
      } else {
        setEndDate(date);
        if (compareTwoDates(startDate, date) === 1) {
          setStartDate(date);
        }
      }

      if (
        isValidDate(date) &&
        (isValidDate(isStartDate ? endDate : startDate) ||
          !(isStartDate ? endDate : startDate))
      ) {
        fetchOrgEducators(
          isStartDate ? date : startDate,
          isStartDate ? endDate : date,
          qualification
        );
      }
    }, 500)();
  };
  useEffect(() => {
    fetchRoleOption();
  }, [fetchRoleOption]);

  useEffect(() => {
    clearFormCreate(centres);
    setQualificationOptions(qualifications);
    changeRole({code: rolesData.ASSISTANT, name: "Assistant", id: 1});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    open,
    centres,
    fetchRoleOption,
    setQualificationOptions,
    qualifications,
    clearFormCreate,
  ]);

  useEffect(() => {
    setSelectedEducator((prevState) => {
      const newEducator = allOrgEducators.find((e) => e.id === prevState?.id);
      return newEducator ? { ...prevState, ...newEducator } : null;
    });
  }, [allOrgEducators, educatorPool]);
  const individualOrFavoriteAssinged = useMemo(() => {
    return (
      isIndividualAssigned(educatorPool) || isFavoriteAssigned(educatorPool)
    );
  }, [educatorPool]);
  return (
    <Dialog sx={styleDialog} open={open} onClose={onCancel}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create a new shift
        </Typography>
        <PaddingBox />
        <M3BodyMedium>Create a new shift for educators to accept</M3BodyMedium>
        <PaddingBox />
        <Row>
          <DropDownButton
            placeholder={"Centre location"}
            currentValue={
              centres.length === 1 && centre === "" ? centres[0].id : centre
            }
            onChange={changeCentre}
            isDisabled={false}
            options={centres.map((centre) => (
              <MenuItem key={centre.id} value={centre.id} dense>
                {centre.name}
              </MenuItem>
            ))}
          />
        </Row>
        <PaddingBox />
        <h4>Shift role or ratio cover?</h4>
        <Row>
          {roleOptions.map((role) =>
            RoleTags({
              role,
              isSelected: currentRole?.code === role?.code,
              onClick: () => changeRole(role),
              qualifications: qualificationOptions,
              currentQualification: qualification,
              onClickQualification: (qualification) =>
                changeQualification(qualification),
            })
          )}
          {/* {(isOwner(orgContext) || isAdmin) &&
            RoleTags({
              role: { id: -1, name: "Any" },
              isSelected: qualification === qualificationData.ANY,
              onClick: () => changeQualification(qualificationData.ANY),
              qualifications: [],
              currentQualification: null,
            })} */}
        </Row>
        <PaddingBox />
        <h4>Shift days</h4>
        <Row>
          <DatePickerTextField
            label={"Start date"}
            onChange={(event) => {
              updateDateFilter(true, event.target.value);
            }}
            value={startDate}
            maxDate={endDateRestriction}
            minDate={today.toISOString().split("T")[0]}
            shouldDisableDate={(date) => {
              const day = date.day();
              return day === 0 || day === 6 || date.isBefore(day(today));
            }}
          />
          <PaddingBox />
          <DatePickerTextField
            id="end-date-picker"
            label={"End date"}
            onChange={(event) => {
              updateDateFilter(false, event.target.value);
            }}
            value={endDate}
            minDate={today.toISOString().split("T")[0]}
            maxDate={endDateRestriction}
            shouldDisableDate={(date) => {
              const day = date.day();
              return day === 0 || day === 6 || date.isBefore(day(today));
            }}
          />
          <PaddingBox />
          <TimeRangePicker
            startTime={startTime}
            endTime={endTime}
            setData={setTimeRange}
          />
        </Row>
        {dateTimeError && (
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.error.main,
              marginTop: "8px",
              marginBottom: "8px",
            }}
          >
            {dateTimeError}
          </Typography>
        )}
        <h4>Shift bonus</h4>
        <Row>
          <DropDownButton
            placeholder={"Shift bonus"}
            currentValue={bonus}
            onChange={changeBonus}
            isDisabled={false}
            options={bonusList.map((option) => (
              <MenuItem key={option} value={option} dense>
                {"$" + option}
              </MenuItem>
            ))}
          />
        </Row>
        <h4>Assign shift to</h4>
        <Row>
          <DropDownButton
            placeholder={"Choose who can see the shift"}
            currentValue={educatorPool}
            onChange={changeEducatorPool}
            isDisabled={assignOptions.length === 0}
            options={assignOptions.map((option) => (
              <MenuItem
                key={option}
                value={option}
                disabled={option === "favorite" && favoriteDisable}
                dense
              >
                {option === "individual-assigned"
                  ? "Individual"
                  : option === "all"
                  ? "Both"
                  : option === "favorite"
                  ? "Favourite"
                  : option === "org"
                  ? orgName
                  : upperCaseFirstLetter(option) + " educators"}
              </MenuItem>
            ))}
          />
        </Row>
        {individualOrFavoriteAssinged && (
          <div>
            <h4>Choose the educator</h4>
            <Row>
              <Autocomplete
                disablePortal
                id="combo-box-educators"
                fullWidth={true}
                options={allOrgEducators}
                getOptionLabel={(option) => option.name}
                value={selectedEducator}
                onChange={(event, educator) => {
                  if (!educator) {
                    setSelectedEducator(null);
                  } else {
                    setSelectedEducator(educator);
                  }
                }}
                isOptionEqualToValue={(option, value) => {
                  if (!option) {
                    return false;
                  }
                  return option !== null && option.id === value.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Educators" />
                )}
              />
            </Row>
          </div>
        )}

        <h4>Description</h4>
        <Row>
          <CommonTextField
            isMultiline={true}
            rows={3}
            label={"Description"}
            value={description}
            onChange={changeDescription}
            isDisable={false}
          />
        </Row>
        <PaddingBox />
        <SpacedRow>
          <CloseButton onClick={onCancel} text={"Cancel"} />
          <Button
            variant="contained"
            disabled={!validateFormCreate()}
            sx={{
              textTransform: "none",
              borderRadius: 28,
              backgroundColor: validateFormCreate()
                ? theme.palette.info.main
                : theme.palette.text.secondary,
              color: "white",
            }}
            onClick={handlePostShift}
          >
            Post shift
          </Button>
        </SpacedRow>
      </Box>
    </Dialog>
  );
}

function RoleTags({
  role,
  isSelected,
  onClick,
  qualifications,
  currentQualification,
  onClickQualification,
}) {
  const selectedColor = theme.palette.secondary.onSecondary;
  const unslectedColor = theme.palette.text.main;
  const selectedBackgroundColor = theme.palette.secondary.main;
  const unselectedBackgroundColor = "white";
  const getLogoComponent = (role, color) => {
    const logoStyle = {
      fill: color,
      width: "30px",
      height: "30px",
    };
    if (role === rolesData.TEACHER) {
      return <EctLogo style={logoStyle} />;
    } else if (role === rolesData.LEADER) {
      return <LeaderLogo style={logoStyle} />;
    } else if (role === rolesData.ASSISTANT) {
      return <AssistantLogo style={logoStyle} />;
    } else {
      return <EctLogo style={logoStyle} />;
    }
  };
  return (
    <Row sx={{ justifyContent: "center" }}>
      <Card
        onClick={onClick}
        variant="outlined"
        sx={{
          padding: 1,
          borderRadius: 2,
          fontWeight: '600',
          marginX: "4px",
          cursor: "pointer",
          backgroundColor: isSelected
            ? selectedBackgroundColor
            : unselectedBackgroundColor,
          color: isSelected ? selectedColor : unslectedColor,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {getLogoComponent(
            role.code,
            isSelected ? selectedColor : unslectedColor
          )}
          <Typography
            sx={{
              fontSize: "12px",
              marginTop: "2px",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            {role.name}
          </Typography>
        </div>
      </Card>
      {qualifications &&
        qualifications.length > 0 &&
        isSelected &&
        qualifications.map((qualification) => {
          const isSelectedQualification =
            qualification.id === currentQualification ||
            ((qualification.id === qualificationData.CERT3 ||
              qualification.id === qualificationData.DIPLOMA) &&
              currentQualification === qualificationData.ASSISTANT);
          const isDisabled = qualifications.length === 1;
          return QualificationChip({
            qualification,
            isSelectedQualification,
            isDisabled,
            onClickQualification,
            currentQualification,
          });
        })}
    </Row>
  );
}

function QualificationChip({
  qualification,
  isSelectedQualification,
  isDisabled,
  onClickQualification,
  currentQualification,
}) {
  function getQualificationForAssistant(qualification) {
    if (
      (qualification === qualificationData.CERT3 &&
        currentQualification === qualificationData.DIPLOMA) ||
      (qualification === qualificationData.DIPLOMA &&
        currentQualification === qualificationData.CERT3)
    ) {
      return qualificationData.ASSISTANT;
    } 
    if (currentQualification === qualificationData.ASSISTANT){
      return qualification === qualificationData.CERT3
        ? qualificationData.DIPLOMA
        : qualificationData.CERT3
    }
    else {
      return qualification;
    }
  }
  return (
    <Chip
      key={qualification.id}
      label={qualification.qualification}
      clickable
      onClick={() => {
        if (isDisabled) return;
        onClickQualification(getQualificationForAssistant(qualification.id));
      }}
      disabled={isDisabled}
      sx={{
        marginLeft: "4px",
        marginBottom: "8px",
        marginRight: "4px",
        backgroundColor: isSelectedQualification
          ? theme.palette.tertiaryContainer.main
          : "white",
        color: isSelectedQualification
          ? theme.palette.tertiaryContainer.onTertiary
          : theme.palette.outline.main,
        borderColor: isSelectedQualification
          ? theme.palette.tertiaryContainer.main
          : theme.palette.outline.main,
        borderRadius: "10px",
        fontWeight: '600',
        fontSize: '13px',
        border: "1px solid",
        "&.Mui-disabled": {
          opacity: 1, // Override default opacity
          backgroundColor: "rgba(103, 80, 164, 0.12)",
          color: theme.palette.onSurfaceVariant.main,
          borderColor: theme.palette.outLineVariant.main,
          // Optional: add a subtle indicator that it's disabled
          cursor: "default",
        },
      }}
    />
  );
}
