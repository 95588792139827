import CheckIcon from "@mui/icons-material/Check";
import theme from "../../../utils/theme";
const { Switch, Box, styled, Typography } = require("@mui/material");
export const CommonSwitchLabel = ({title}) => (
  <Typography
    sx={{
      fontSize: "16px",
      textAlign: "left",
      fontWeight: "600",
      marginLeft: "10px",
    }}
  >
    {title}
  </Typography>
);
export const CommonSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    checkedIcon={
      <Box
        sx={{
          boxSizing: "border-box",
          width: 22,
          height: 22,
          bgcolor: "common.white",
          borderRadius: "50%", // Ensure perfect circle
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 2px 4px rgba(0,0,0,0.2)", // Add elevation shadow
        }}
      >
        <CheckIcon
          fontSize="small"
          style={{
            color: theme.palette.primary.main,
            width: "18px",
            height: "18px",
          }}
        />
      </Box>
    }
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: theme.palette.primary.main,
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: theme.palette.primary.main,
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));
