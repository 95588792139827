import React from 'react'
import { Chip } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { M3TitleSmall } from '../../../../reusable/TextStyles';
import { ReactComponent as IssueIcon } from "../../../../../assets/issue-icon.svg";
import { ReactComponent as VerifyingIcon } from "../../../../../assets/verifying-icon.svg";

const AccountStatus = ({ label, status }) => {
  const colors = {
    Complete: '#5a60d5',
    Verified: '#6fbd65',
    Verifying: '#ed6c02',
    Issue: 'red',
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Issue':
        return <IssueIcon style={{ fontSize: '15px', marginLeft: '2px' }} />
      case 'Verifying':
        return <VerifyingIcon style={{ fontSize: '15px', marginLeft: '2px' }} />
      default:
        return <CheckCircle style={{ fontSize: '15px', marginLeft: '2px' }} />
    }
  }

  return (
    <>
      <M3TitleSmall variant="body1" component="div" sx={{ width: '150px', wordBreak: 'break-all' }}>
        {label}
      </M3TitleSmall>
      <Chip
        label={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {status}
            {getStatusIcon(status)}
          </span>
        }
        size="small" 
        sx={{backgroundColor: colors[status], color: 'white'}}
      />
    </>
  )
}

export default AccountStatus